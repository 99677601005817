import React from 'react';
import Footer from '../components/Footer';
import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <section>
      <div className="container">
        <h2>General</h2>
        <br />
        <p>
          Thank you for choosing Fotoo! When accessing Fotoo’s services, you agree to
          the terms listed below. Please read the terms and conditions very carefully
          before using Fotoo. We reserve the right to change this policy at any time
          without notice, please check it regularly.
        </p>
        <p>
          This is the most recent terms of services agreements as of Feb 2024.
        </p>
        <br />
        <br />
        <h2>Terms of Use</h2>
        <br />
        <p>
          Fotoo is not affiliated with Google, Microsoft, Dropbox or any other third
          party services. By using Fotoo, you acknowledge that Fotoo is not to be held
          responsible for any downtime directly caused by these services.
        </p>
        <p>
          Fotoo services do not generate content. All media content displayed in the
          app is sourced completely from linked user library based on user consent.
          By using Fotoo, you acknowledge that we expressly exclude libability for any
          consequences of the usage of inappropriate user content to the fullest extent
          permitted by law.
        </p>
        <p>
          The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.
        </p>
        <br />
        <br />
        <h2 id="pp">Privacy Policy</h2>
        <br />
        <p>
          Here we describe how we collect and use your information when you use the software
          application Fotoo (“Application”) for mobile devices that was created by Bopp Studio.
        </p>
        <h4>&nbsp;</h4>
        <h4>What information does the Application obtain and how is it used?</h4>
        <p><strong>User Provided Information</strong></p>
        <p>
          When using the Application, depending on your specific choices of photo sources, you may 
          provide your user data or account information of Google, Microsoft, Dropbox and/or other 
          cloud services including your name, email and profile photo. However the information would 
          not be collected by any remote services and may only be used locally, on-device for the 
          Application to function properly.
        </p>
        <p>&nbsp;</p>
        <p><strong>Automatically Collected Information</strong></p>
        <p>
          In addition, the Application may collect certain information automatically, including, but
          not limited to, the type of mobile device you use, your mobile devices unique device ID,
          the IP address of your mobile device, your mobile operating system, the type of mobile
          Internet browsers you use, and additional information about the way you use the Application.
        </p>
        <p></p>
        <h4>&nbsp;</h4>
        <h4>Does the Application collect precise real time location information of the device?</h4>
        <p></p>
        <p>
          This Application does not collect precise information about the location of your mobile 
          device.
        </p>
        <p>&nbsp;</p>
        <h4>Do third parties see and/or have access to information obtained by the Application?</h4>
        <p></p>
        <p>
          Yes. We will share your information with third parties only in the ways that are described 
          in this privacy statement.
        </p>
        <p>We may disclose User Provided and Automatically Collected Information:</p>
        <ul>
          <li> <p>as required by law, such as to comply with a subpoena, or similar legal process;</p> </li>
          <li> <p>when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</p> </li>
          <li> <p>with our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</p> </li>
          <li>to advertisers and third party advertising networks and analytics companies as described in the section below</li>
        </ul>
        <p>&nbsp;</p>
        <h4>Automatic Data Collection and Advertising</h4>
        <p></p>
        <p>
          We may work with analytics companies to help us understand how the Application is being 
          used, such as the frequency and duration of usage. We work with advertisers and third 
          party advertising networks, who need to know how you interact with advertising provided 
          in the Application which helps us keep the cost of the Application low. Advertisers and 
          advertising networks use some of the information collected by the Application, including, 
          but not limited to, the unique identification ID of your mobile device and your mobile 
          telephone number. To protect the anonymity of this information, we use an encryption 
          technology to help ensure that these third parties can’t identify you personally. These 
          third parties may also obtain anonymous information about other applications you’ve downloaded 
          to your mobile device, the mobile websites you visit, your non-precise location information 
          (e.g., your zip code), and other non- precise location information in order to help analyze 
          and serve anonymous targeted advertising on the Application and elsewhere. We may also share 
          encrypted versions of information you have provided in order to enable our partners to append 
          other available information about you for analysis or advertising related use.
        </p>
        <p></p>
        <p>
          If you’d like to opt-out from third party use of this type of information to help serve targeted 
          advertising, please visit the section entitled “Opt-out” below.
        </p>
        <p>&nbsp;</p>
        <h4>Opt-out</h4>
        <p></p>
        <p>There are multiple opt-out options for users of this Application:</p>
        <p><u>Opt-out of all information collection by uninstalling the Application: </u>You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.</p>
        <p><u>Opt-out from the use of information to serve targeted advertising by advertisers and/or third party network advertisers:</u> you may at any time opt-out from further allowing us to have access to your location data by describe how user can manage their location preferences either from the device level.</p>
        <p>&nbsp;</p>
        <h4><strong>Data Retention Policy, Managing Your Information</strong></h4>
        <p>
          We will not retain User Provided data. We will retain Automatically Collected information for 
          up to 24 months and thereafter may store it in aggregate.
        </p>
        <p>&nbsp;</p>
        <h4><strong>Children</strong></h4>
        <p></p>
        <p>
          We do not use the Application to knowingly solicit data from or market to children under the age 
          of 13. If a parent or guardian becomes aware that his or her child has provided us with information 
          without their consent, he or she should contact us via email. We will delete such information from 
          our files within a reasonable time.
        </p>
        <p>&nbsp;</p>
        <h4><strong>Changes</strong></h4>
        <p>
          This Privacy Policy may be updated from time to time for any reason. You are advised to consult this 
          Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
        </p>
        <p>&nbsp;</p>
        <h4><strong>Your Consent</strong></h4>
        <p>
          By using the Application, you are consenting to our processing of your information as set forth 
          in this Privacy Policy now and as amended by us. "Processing" means using cookies on a computer/hand 
          held device or using or touching information in any way, including, but not limited to, collecting, 
          storing, deleting, using, combining and disclosing information, all of which activities will take 
          place in the United States. If you reside outside the United States your information will be 
          transferred, processed and stored there under United States privacy standards.
        </p>
        <p>&nbsp;</p>
        <h4>Contact us</h4>
        <p>
          If you have any questions regarding privacy while using the Application, or have questions about our 
          practices, please contact us via email listed on <a href="https://play.google.com/store/apps/details?id=com.bo.fotoo">Google Play.</a>
        </p>
      </div>
    </section>
    <Footer />
  </Layout>
);

export default IndexPage;
